<!--设备列表-->
<template>
	<div class="page">
		<Tables :showSeq="false" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
		 :showAdd="$hasAccess('group.bill/index')" addName="添加卡现金收入" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
<!--				<el-select v-model="searchFormData.group_id" placeholder="按所属小区查询" clearable class="from-item-width">-->
<!--					<el-option v-for="group in groupList" :key="group.group_id" :label="group.group_name" :value="group.group_id"></el-option>-->
<!--				</el-select>-->
				<el-select
						v-model="searchFormData.group_id"
						size="small"
						class="from-item-width"
						filterable
						reserve-keyword
						clearable
						placeholder="按所属小区查询"
						:remote-method="remoteGroup"
						:loading="groupLoading">
					<el-option
							v-for="item in groupList"
							:key="item.group_id"
							:label="item.group_name"
							:value="item.group_id">
					</el-option>
				</el-select>
				<el-form-item>
					<el-date-picker class="from-item-width" size="small" style="width: 50%"  unlink-panels v-model="dates" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_id" title="小区ID" align="center" />
			<vxe-table-column slot="table-item" field="group_name" title="小区名称" align="center" min-width="200px" />
			<vxe-table-column slot="table-item" field="record_money" title="入账金额" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="share_price" title="代理商提成" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="share_status" title="分销结算状态" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span :class="shareStatus[row.share_status].class">{{shareStatus[row.share_status].txt || '未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="describ" title="入账描述" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="create_user_id" title="录入人ID" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="create_user_name" title="录入人姓名" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="create_time" title="录入时间" align="center" min-width="100px" />
		</Tables>
		<!-- 编辑 -->
		<el-dialog title="现金收入增加" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="小区名称" prop="group_id">
<!--					<el-select class="select" v-model="addDialogFormData.group_id" placeholder="请选择小区" clearable>-->
<!--						<el-option v-for="group in groupList" :key="group.group_id" :label="group.group_name" :value="group.group_id"></el-option>-->
<!--					</el-select>-->
					<el-select
							v-model="addDialogFormData.group_id"
							size="small"
							style="width: 100%"
							filterable
							reserve-keyword
							clearable
							placeholder="请选择小区"
							:remote-method="remoteGroup"
							:loading="groupLoading">
						<el-option
								v-for="item in groupList"
								:key="item.group_id"
								:label="item.group_name"
								:value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入账金额" prop="record_money">
					<el-input v-model="addDialogFormData.record_money" placeholder="请输入入账金额" clearable></el-input>
				</el-form-item>
				<el-form-item label="入账描述" prop="describ">
					<el-input v-model="addDialogFormData.describ" placeholder="入账描述" clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()" v-if="$hasAccess('group.bill/index')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from "@/components/tables";

	export default {
		name: "bill-group-list",
		components: {
			Tables,
		},
		data() {
			return {
				// table信息
				tableName: "设备清单",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				dates: [],
				searchFormData: {},
				billList: [],
				groupList: [],
				shareStatus: {
					10: {
						txt: '不结算',
						class: 'sd_spare2'
					},
					20: {
						txt: '未结算',
						class: 'sd_error',
					},
					30: {
						txt: '已结算',
						class: 'sd_spare'
					}
				},
				addDialogShow: false,
				addDialogFormData: {},
				rules: {
					group_id: [{
						required: true,
						message: '请选择小区',
						trigger: ['blur', 'change']
					}],
					record_money: [{
						required: true,
						message: '请输入入账金额',
						trigger: ['blur', 'change']
					}],
				}
			};
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
			this.$refs.xTable.refreshTable();
		},
		methods: {
			// 初始化请求
			init() {
				this.getGroupList();
			},
			// 获取小区信息
			async getGroupList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 1000,
				};
				const res = await this.$api.Device.GetGroupList(params, {});
				this.groupList = res.data;
			},
			// 获取列表
			async getList(currentPage, pageSize) {
				if (this.dates[0]){
					this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
					this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
				}
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize,
					group_id: this.searchFormData.group_id,
					form: JSON.stringify(this.searchFormData)
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				const res = await this.$api.Bill.GetGroupBillList(params);
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 提交记录
			async addGroupBill() {
				const form = {
					group_id: this.addDialogFormData.group_id,
					record_money: this.addDialogFormData.record_money,
					describ: this.addDialogFormData.describ,
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(form),
				}
				await this.$api.Bill.AddGroupBill(params);
				this.$notify({
					title: "成功",
					message: "添加成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();

			},
			// 小区
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 重置查询
			clearSearch() {
				this.dates = [];
				this.searchFormData = {};
			},
			// 新增设备
			addOne() {
				this.addDialogShow = true;
			},
			saveAdd() {
				if (!this.addDialogFormData.group_id) {
					this.$refs.addForm.validateField('group_id');
					return;
				}
				if (!this.addDialogFormData.record_money) {
					this.$refs.addForm.validateField('record_money');
					return;
				}
				this.$refs.addForm.validateField('describ');
				this.addGroupBill();
			},
			closeDialog() {
				this.addDialogShow = false;
			}
		},
	};
</script>

<style lang="less" scoped>
	.from-item-width {
		width: 180px;
	}

	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
	}

	.sd_error {
		color: #f56c6c;
	}

	.sd_total {
		color: #b28e2f;
	}

	.add-dialog-form {
		.select {
			width: 100%;
		}
	}
</style>
